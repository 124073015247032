import React, { useState, useRef } from 'react';
import '../FullScreenButton.css'

const MobileGame = () => {
  const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
  const iframeRef = useRef(null); // Reference to the iframe

  // Function to handle full-screen toggle
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full-screen mode
      if (iframeRef.current) {
        iframeRef.current.requestFullscreen()
          .then(() => setIsFullScreen(true))
          .catch(err => console.error("Failed to enter full-screen mode:", err));
      }
    } else {
      // Exit full-screen mode
      document.exitFullscreen()
        .then(() => setIsFullScreen(false))
        .catch(err => console.error("Failed to exit full-screen mode:", err));
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', paddingTop: '50px' }}>
      {/* Full-Screen Toggle Button */}
      
      {/* Iframe for the game */}
      <iframe
        ref={iframeRef}
        src="/mobile-game/FV_web_mobile.html"
        title="Mobile Game"
        style={{ width: '100%', height: '80vh', border: 'none' }} // Adjust height for mobile screen
      />

      <button onClick={toggleFullScreen} className='FullScreenButton'>
        {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
      </button>

    </div>
  );
};

export default MobileGame;
