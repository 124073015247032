import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import './Components/DataDeletePage'
import DataDeletePage from './Components/DataDeletePage';
import HomePage from './Components/HomePage';
import GamesPage from './Components/GamesPage';
import AboutPage from './Components/AboutPage';
import ContactPage from './Components/ContactPage';
import BecomeTester from './Components/BecomeTester'
import NavbarMenu from './Components/NavbarMenu.js';
import BlogPage from './Components/BlogPage';
import Footer from './Components/Footer';
import BlogPost from './Components/BlogPost';
import GameSelection from './Components/GameSelection';
import DesktopGame from './Components/DesktopGame';
import MobileGame from './Components/MobileGame';
import NeonHorizonWeb from './Components/NeonHorizonWeb.js';
import { Banner } from "exoclick-react";
import ExoClickAd from './Components/ExoClickAd.js';


function App() {


  return (
    <Router>
      <div className="App">
        <nav className='nav-bar' style={{
                                    position: 'fixed',
                                    display: 'flex',
                                    justifyContent: 'flex-start',
                                    alignItems: 'center',
                                    padding: '10px',
                                    backgroundColor: 'black',
                                    color: 'white',
                                  }}>
                                    <NavbarMenu />
            <ul>
                <li>
                </li>
             </ul>
          <div>
            <h1 className='devName'>ＤＩＳＳＯＮＡＮＴ ＤＲＵＩＤ</h1>
          </div>
        </nav>
        
      

          <Routes>
            <Route index element={<HomePage />} />
            <Route path="/privacy-notice" element={<DataDeletePage />} />
            <Route path="/games" element={<GamesPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path='/betester' element={<BecomeTester />} />
            <Route path='/blog-updates' element={<BlogPage />} />
            <Route path='/blog-updates/:postId' element={<BlogPost />} />
            <Route path='/final-void-online' element={<GameSelection />} />
            <Route path="/desktop-game" element={<DesktopGame />} />
            <Route path="/mobile-game" element={<MobileGame />} />
            <Route path='/neon-horizon' element={<NeonHorizonWeb />} />
          </Routes>
      </div>

    <div className='Banner'>
    <Banner zoneId={5443850} />
    <Banner zoneId={5445298} />
    </div>

    <Footer />
    </Router>
  );
}

export default App;
