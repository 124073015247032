import React from 'react';
import { Link } from 'react-router-dom';
import '../GameStyles.css';
import gameScreenshot1 from '../static/neon_horizon_screenshot.jpg';
import gameScreenshot2 from '../static/neon_horizon_screenshot_2.jpg';
import gameScreenshot3 from '../static/neon_horizon_screenshot_3.jpg';
import gameScreenshot4 from '../static/final_void/FV_screenshot_1.jpg';
import gameScreenshot5 from '../static/final_void/FV_screenshot_2.jpg';
import gameScreenshot6 from '../static/final_void/FV_screenshot_3.jpg';
import { Banner } from 'exoclick-react';


const games = [
  {
    name: 'NEON HORIZON',
    description: 'Dive into the neon-lit world of our synthwave-themed endless runner. Dodge obstacles at breakneck speeds, all while soaking in the vibrant, retro-futuristic visuals and pulsating synthwave soundtrack. Are you ready to test your reflexes in this fast-paced action game?',
    url: 'https://play.google.com/store/apps/details?id=com.neonhorizon.release&hl=en_US',
    releaseDate: '07-07-2024',
    playOnline: '/neon-horizon',
    screenshots: [
      gameScreenshot1,
      gameScreenshot2,
      gameScreenshot3
    ]
  },
  {
    name: 'Final Void',
    description: 'Dive into the shadows of the unknown in Final Void, a 2D platformer where you control a powerful wizard on a perilous quest through eerie landscapes. Face off against terrifying eldritch monsters in order to find ancient relics. With challenging levels and a dark, Lovecraftian atmosphere, Final Void will test your skills as you battle through a world filled with sinister creatures and hidden dangers. Please enjoy this 3 level demo while the full game is in development. Can you survive the final void?',
    url: 'https://play.google.com/store/apps/details?id=com.finalvoid.mobile&pcampaignid=web_share',
    releaseDate: '09-25-2024',
    playOnline: '/final-void-online',
    screenshots: [
      gameScreenshot4,
      gameScreenshot5,
      gameScreenshot6
    ]
  },
  // Add more games as needed
];

const GamesPage = () => {

  const sortedGames = [...games].sort((a, b) => new Date(b.releaseDate) - new Date(a.releaseDate));


  return (
    <div className='GamesPage'>
      <h1 className='PageTitle'>OUR GAMES</h1> 
      
      {sortedGames.map((game, index) => (
        <div key={index}>
          {/* Game Section */}
          <div className='GameSection'>
            <h1 className='GameTitle'>{game.name}</h1>
            <p className='GameDescription'>{game.description}</p>
            <div className='Screenshots'>
              {game.screenshots.map((screenshot, idx) => (
                <img key={idx} src={screenshot} alt={`Screenshot ${idx + 1}`} className='Screenshot' />
              ))}
            </div>
            <div className='ButtonContainer'>
              <a href={game.url} target="_blank" rel="noopener noreferrer" className='PlayStoreLink'>
                <button className='PlayStoreButton'>Get it on Google Play</button>
              </a>
              {game.playOnline && (
                <Link to={game.playOnline}>
                  <button className="PlayOnlineButton">Play it online!</button>
                </Link>
              )}
            </div>
          </div>

          {/* Always render Banner after each game */}
          <div className='AdContainer'>
            <Banner zoneId={5443366} />
            
          </div>
        </div>
      ))}
    </div>
  );
};

export default GamesPage;
