import React, { useEffect, useRef } from 'react';

type SocialBarProps = {
  src: '//pl24627651.cpmrevenuegate.com/c6/d7/37/c6d7371477491cb34c7b428c4569d4eb.js'
};

const SocialBar: React.FC<SocialBarProps> = ({ src }) => {
  const adRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (adRef.current) {
      // Create a new script element
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.src = src;
      script.async = true; // Optional: Load script asynchronously

      // Append the script to the ad container div
      adRef.current.appendChild(script);
    }
  }, [src]); // Re-run the effect only if `src` changes

  return (
    <div ref={adRef}>
      {/* This div will hold the social bar ad */}
    </div>
  );
};

export default SocialBar;
