import React, { useState, useRef } from 'react';
import '../FullScreenButton.css'
import SocialBar from './SocialBar.tsx';

const DesktopGame = () => {
  const [isFullScreen, setIsFullScreen] = useState(false); // Track full-screen state
  const iframeRef = useRef(null); // Reference to the iframe

  // Function to handle full-screen toggle
  const toggleFullScreen = () => {
    if (!isFullScreen) {
      // Enter full-screen mode
      if (iframeRef.current) {
        iframeRef.current.requestFullscreen()
          .then(() => setIsFullScreen(true))
          .catch(err => console.error("Failed to enter full-screen mode:", err));
      }
    } else {
      // Exit full-screen mode
      document.exitFullscreen()
        .then(() => setIsFullScreen(false))
        .catch(err => console.error("Failed to exit full-screen mode:", err));
    }
  };

  return (
    <div style={{ width: '100%', height: '100%', paddingTop: '100px' }}>
      
      {/* Iframe for the game */}
      <iframe
        ref={iframeRef}
        src="/desktop-game/FV_web_desktop.html"
        title="Desktop Game"
        style={{ width: '100%', height: '80vh', border: 'none' }} // Style adjustment
      />

            {/* Full-Screen Toggle Button */}
      <button onClick={toggleFullScreen} className='FullScreenButton'>
        {isFullScreen ? 'Exit Full Screen' : 'Full Screen'}
      </button>

      <div style={{ marginTop: '20px' }}>
        <a 
          href="https://www.cpmrevenuegate.com/wit6cme8q4?key=45e31620afd5f6db5cdfd5cf279dd52c"
          target="_blank" 
          rel="noopener noreferrer" 
          style={{ 
            textDecoration: 'none', 
            color: 'blue', 
            fontSize: '20px' 
          }}
        >
          Click here to check out this great offer!
        </a>
      </div>
    <SocialBar />
    </div>
  );
};

export default DesktopGame;
